<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" status="success" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        component="h6"
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10 py-2"
      >
        User Profile
      </CDropdownHeader>
      <p class="m-2">
        <b>{{ user.firstname }} {{ user.lastname }}</b>
        <br>
        {{ user.email }}
        <br>
        {{ user.phone }}
        <br>
        <small>{{ user.role }}</small>
        <br>
        <small>{{ user.organization }}</small>
      </p>
      <!-- <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem> -->
      <CDropdownDivider />
      <CDropdownItem @click="logout()"> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/user_icon.png'
import axios from 'axios';
import store from '../store';
export default {
  name: 'AppHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42,
      avatar : avatar,
      baseURL : store.state.baseURL,
      user : store.state.user,
      profileImageUrl : '/img/user_icon.png',
      name : '',
      phone : '',
      email : '',
      roles : {
        public : "Citizen",
        researcher : "Researcher",
        watchers : "Watchers or RRTs",
        rfo : "RFO & above",
        dfo : "DFO & above",
        ccf : "CCF & above",
        admin : "System Admin"
      },
    }
  },
  created () {
    this.isLoggedIn();
  },
  methods : {
    logout(){
      axios({
          url : this.baseURL + "user/logout",
          method : "GET",
          headers : {
            authorization : store.state.token
          }
        })
      localStorage.removeItem('token')
      store.commit('logoutUser')
      this.$router.push({ name: 'Login' })
    },
    isLoggedIn(){
      axios({
        url : this.baseURL + "isLoggedIn",
        method : "GET",
        headers : {
          authorization : store.state.token
        }
      })
      .then(response => {
        if(!response.data.status){
          axios({
            url : this.baseURL + "admin/logout",
            method : "GET",
            headers : {
              authorization : store.state.token
            }
          })
          localStorage.removeItem('token')
          store.commit('logoutUser')
          this.$router.push({ name: 'Login' })
        }
        else{
          store.commit('loginUser', {
              token : response.data.data.token,
              user  : response.data.data
          });
        }
      })
      .catch(err => {
        this.logout();
        console.log(err);
        this.$notify({
            group: 'notification',
            type : 'error',
            title: 'Error in authentication',
            text: "Please login again!"
        });
      })      
    }
  }
  
}
</script>
