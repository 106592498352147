import { createStore } from 'vuex'

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: 'default',
    isFilterModalOpen: false,
    isLoggedIn: !!localStorage.getItem("token"),
    isLog : !!localStorage.getItem("token"),
    baseURL : 'https://hclstaging.animos.org/api/',
    // baseURL : 'http://localhost:4050/api/',
    token : localStorage.getItem("token"),
    user : JSON.parse(localStorage.getItem("user")),
    filters : localStorage.getItem("filters")!= undefined ? JSON.parse(localStorage.getItem("filters")) : {}
  },
  mutations: {
    SET_FILTERS(state, filters) {
      state.filters = filters;
      console.log(filters)
    },
    TOGGLE_FILTER_MODAL(state, status) {
      state.isFilterModalOpen = status;
    },
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
      console.log("Toggle Aside : ");
      console.log(state)
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
      console.log("Toggle Sidebar : ");
      console.log(state)
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;   
      console.log("Toggle Sidebar Visible : ");
      console.log(state)   
      console.log(payload) 
    },
    set (state, [variable, value]) {
      state[variable] = value
    },
    loginUser (state, payload) {
        state.isLoggedIn = true
        state.isLog = true
        state.token = payload.token;
        state.user = payload.user;
    },
    logoutUser (state) {
        state.isLoggedIn = false
        state.isLog = false
        state.token = null
        state.user = null
    }

  },
  actions: {
    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters);
    },
    toggleFilterModal({ commit }, status) {
      commit('TOGGLE_FILTER_MODAL', status);
    }
  },
  modules: {

  },
  getters: {
    getFilters: (state) => state.filters,
    isModalOpen: (state) => state.isFilterModalOpen
  }
})
